import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import PaymentModal from "components/helpers/PaymentModal";
import React, { useState } from "react";
import "./PaymentStyles.css"; // Ensure you import the styles
import baseUrl from "utils/baseUrl";
import axios from "axios";
import toast from "react-hot-toast";

const Payment = ({ show, onClose, tier, amount, payment_link }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [walletAddress, setWalletAddress] = useState("");
  const [email, setEmail] = useState(""); // Placeholder for email, manage state appropriately
  console.log("amount: ", amount);
  const [loader, setLoader] = useState(false);

  const handlePaymentClick = () => {
    onClose();
    window.open(payment_link, '_blank', 'noopener,noreferrer');
  };

  const buttonStyle = {
    width: '100%',
    padding: '8px 16px',
    color: 'white',
    fontWeight: '600',
    borderRadius: '0.5rem', // Tailwind CSS class rounded-lg
    outline: 'none',
    transition: 'background-color 0.2s ease-in-out',
    display: 'flex',
    alignItems: 'cenetr',
    justifyContent: 'center',
  };
  console.log("walletAddress",walletAddress);
  console.log("tier",tier);
  
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      console.log("function called!!!!");
      setLoader(true);
      console.log("form submission");

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        console.log("Stripe.js has not loaded yet.");
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          //   walletAddress,
          email,
        },
      });

      if (error) {
        console.log("Error:", error);
        setLoader(false);

        return;
      }

      console.log("PaymentMethod:", paymentMethod);

      const response = await axios.post(`${baseUrl}/api/admin/mint`, {
        paymentMethodId: paymentMethod.id,
        email,
        amount,
      });
      

      console.log("response1:", response?.data);

      console.log("response:", response?.data?.client_secret);
      if (response?.data?.client_secret) {
        // confirm the payment by the user
        const confirmPayment = await stripe?.confirmCardPayment(
          response?.data?.client_secret
        );
        console.log("confirmPayment", confirmPayment);
        if (confirmPayment?.error) {
          console.log("error occ: ", confirmPayment.error.message);
        } else {
          console.log("Success! Check your email for the invoice.");
          toast.success("Nft will be air dropped on your wallet");
          onClose();

          const response = await axios.post(`${baseUrl}/api/admin/adminMint`, {
            walletAddress: walletAddress,
            tier,
          });

          

          console.log("nftresponse: ", response);

          // console.log("minting response:", response?.data);
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);

      console.log("error:", error);
    }
  };

  return (
    <PaymentModal show={show} onClose={onClose} className="p10">
      <form onSubmit={handleSubmit} className="paymentForm">
        <h2 className="mb-5">
          <b className="payment-heading">Payment Information</b>
        </h2>
        <input
          type="text"
          placeholder="Wallet Address"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div>
          <CardNumberElement className="StripeElement" />
          <CardExpiryElement className="StripeElement" />
          <CardCvcElement className="StripeElement" />
        </div>
        <button disabled={loader ? true : false} type="submit">
          {loader ? "Minting NFT..." : "Submit Payment"}
        </button>
      </form>
      <div className="installmentsBtnContainer">
        <button
          type="button"
          onClick={handlePaymentClick}
          style={buttonStyle}
        >
          Pay with Installment
        </button>
      </div>
    </PaymentModal>
  );
};

export default Payment;
