import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import FlipCard, { BackCard, FrontCard } from "components/FlipCard";
import "styles/roadmap.css";
import * as Top from "components/backgrounds/TopBackground";
import * as Bottom from "components/backgrounds/BottomBackground";

const Founders = () => {
  const [skyColor, setSkyColor] = useAtom(Top.skyColorAtom);
  const [initialBGOpacity, setInitialBGOpacity] = useAtom(
    Top.initialBGOpacityAtom
  );
  const [animateBGOpacity, setAnimateBGOpacity] = useAtom(
    Top.animateBGOpacityAtom
  );
  const [initialStarsTop, setInitialStarsTop] = useAtom(
    Top.initialStarsTopAtom
  );
  const [animateStarsTop, setAnimateStarsTop] = useAtom(
    Top.animateStarsTopAtom
  );
  const [initialStarsLeft, setInitialStarsLeft] = useAtom(
    Top.initialStarsLeftAtom
  );
  const [animateStarsLeft, setAnimateStarsLeft] = useAtom(
    Top.animateStarsLeftAtom
  );
  const [initialStarsOpacity, setInitialStarsOpacity] = useAtom(
    Top.initialStarsOpacityAtom
  );
  const [animateStarsOpacity, setAnimateStarsOpacity] = useAtom(
    Top.animateStarsOpacityAtom
  );
  const [initialSunTop, setInitialSunTop] = useAtom(Top.initialSunTopAtom);
  const [animateSunTop, setAnimateSunTop] = useAtom(Top.animateSunTopAtom);
  const [initialSunLeft, setInitialSunLeft] = useAtom(Top.initialSunLeftAtom);
  const [animateSunLeft, setAnimateSunLeft] = useAtom(Top.animateSunLeftAtom);

  const [initialMainBottom, setInitialMainBottom] = useAtom(
    Bottom.initialMainBottomAtom
  );
  const [animateMainBottom, setAnimateMainBottom] = useAtom(
    Bottom.animateMainBottomAtom
  );
  const [initialMainLeft, setInitialMainLeft] = useAtom(
    Bottom.initialMainLeftAtom
  );
  const [animateMainLeft, setAnimateMainLeft] = useAtom(
    Bottom.animateMainLeftAtom
  );
  const [initialMainOpacity, setInitialMainOpacity] = useAtom(
    Bottom.initialMainOpacityAtom
  );
  const [animateMainOpacity, setAnimateMainOpacity] = useAtom(
    Bottom.animateMainOpacityAtom
  );
  const [initialMainScale, setInitialMainScale] = useAtom(
    Bottom.initialMainScaleAtom
  );
  const [animateMainScale, setAnimateMainScale] = useAtom(
    Bottom.animateMainScaleAtom
  );
  const [initialMintBottom, setInitialMintBottom] = useAtom(
    Bottom.initialMintBottomAtom
  );
  const [animateMintBottom, setAnimateMintBottom] = useAtom(
    Bottom.animateMintBottomAtom
  );
  const [initialMintLeft, setInitialMintLeft] = useAtom(
    Bottom.initialMintLeftAtom
  );
  const [animateMintLeft, setAnimateMintLeft] = useAtom(
    Bottom.animateMintLeftAtom
  );
  const [initialMintOpacity, setInitialMintOpacity] = useAtom(
    Bottom.initialMintOpacityAtom
  );
  const [animateMintOpacity, setAnimateMintOpacity] = useAtom(
    Bottom.animateMintOpacityAtom
  );
  const [initialMintScale, setInitialMintScale] = useAtom(
    Bottom.initialMintScaleAtom
  );
  const [animateMintScale, setAnimateMintScale] = useAtom(
    Bottom.animateMintScaleAtom
  );
  const [initialTimelineBottom, setInitialTimelineBottom] = useAtom(
    Bottom.initialTimelineBottomAtom
  );
  const [animateTimelineBottom, setAnimateTimelineBottom] = useAtom(
    Bottom.animateTimelineBottomAtom
  );
  const [initialTimelineLeft, setInitialTimelineLeft] = useAtom(
    Bottom.initialTimelineLeftAtom
  );
  const [animateTimelineLeft, setAnimateTimelineLeft] = useAtom(
    Bottom.animateTimelineLeftAtom
  );
  const [initialTimelineOpacity, setInitialTimelineOpacity] = useAtom(
    Bottom.initialTimelineOpacityAtom
  );
  const [animateTimelineOpacity, setAnimateTimelineOpacity] = useAtom(
    Bottom.animateTimelineOpacityAtom
  );
  const [initialTimelineScale, setInitialTimelineScale] = useAtom(
    Bottom.initialTimelineScaleAtom
  );
  const [animateTimelineScale, setAnimateTimelineScale] = useAtom(
    Bottom.animateTimelineScaleAtom
  );
  const [initialAboutBottom, setInitialAboutBottom] = useAtom(
    Bottom.initialAboutBottomAtom
  );
  const [animateAboutBottom, setAnimateAboutBottom] = useAtom(
    Bottom.animateAboutBottomAtom
  );
  const [initialAboutLeft, setInitialAboutLeft] = useAtom(
    Bottom.initialAboutLeftAtom
  );
  const [animateAboutLeft, setAnimateAboutLeft] = useAtom(
    Bottom.animateAboutLeftAtom
  );
  const [initialAboutOpacity, setInitialAboutOpacity] = useAtom(
    Bottom.initialAboutOpacityAtom
  );
  const [animateAboutOpacity, setAnimateAboutOpacity] = useAtom(
    Bottom.animateAboutOpacityAtom
  );
  const [initialAboutScale, setInitialAboutScale] = useAtom(
    Bottom.initialAboutScaleAtom
  );
  const [animateAboutScale, setAnimateAboutScale] = useAtom(
    Bottom.animateAboutScaleAtom
  );
  const [initialFoundersBottom, setInitialFoundersBottom] = useAtom(
    Bottom.initialFoundersBottomAtom
  );
  const [animateFoundersBottom, setAnimateFoundersBottom] = useAtom(
    Bottom.animateFoundersBottomAtom
  );
  const [initialFoundersLeft, setInitialFoundersLeft] = useAtom(
    Bottom.initialFoundersLeftAtom
  );
  const [animateFoundersLeft, setAnimateFoundersLeft] = useAtom(
    Bottom.animateFoundersLeftAtom
  );
  const [initialFoundersOpacity, setInitialFoundersOpacity] = useAtom(
    Bottom.initialFoundersOpacityAtom
  );
  const [animateFoundersOpacity, setAnimateFoundersOpacity] = useAtom(
    Bottom.animateFoundersOpacityAtom
  );
  const [initialFoundersScale, setInitialFoundersScale] = useAtom(
    Bottom.initialFoundersScaleAtom
  );
  const [animateFoundersScale, setAnimateFoundersScale] = useAtom(
    Bottom.animateFoundersScaleAtom
  );
  const [initialVisionBottom, setInitialVisionBottom] = useAtom(
    Bottom.initialVisionBottomAtom
  );
  const [animateVisionBottom, setAnimateVisionBottom] = useAtom(
    Bottom.animateVisionBottomAtom
  );
  const [initialVisionLeft, setInitialVisionLeft] = useAtom(
    Bottom.initialVisionLeftAtom
  );
  const [animateVisionLeft, setAnimateVisionLeft] = useAtom(
    Bottom.animateVisionLeftAtom
  );
  const [initialVisionOpacity, setInitialVisionOpacity] = useAtom(
    Bottom.initialVisionOpacityAtom
  );
  const [animateVisionOpacity, setAnimateVisionOpacity] = useAtom(
    Bottom.animateVisionOpacityAtom
  );
  const [initialVisionScale, setInitialVisionScale] = useAtom(
    Bottom.initialVisionScaleAtom
  );
  const [animateVisionScale, setAnimateVisionScale] = useAtom(
    Bottom.animateVisionScaleAtom
  );
  const [initialTokenomicsBottom, setInitialTokenomicsBottom] = useAtom(
    Bottom.initialTokenomicsBottomAtom
  );
  const [animateTokenomicsBottom, setAnimateTokenomicsBottom] = useAtom(
    Bottom.animateTokenomicsBottomAtom
  );
  const [initialTokenomicsLeft, setInitialTokenomicsLeft] = useAtom(
    Bottom.initialTokenomicsLeftAtom
  );
  const [animateTokenomicsLeft, setAnimateTokenomicsLeft] = useAtom(
    Bottom.animateTokenomicsLeftAtom
  );
  const [initialTokenomicsOpacity, setInitialTokenomicsOpacity] = useAtom(
    Bottom.initialTokenomicsOpacityAtom
  );
  const [animateTokenomicsOpacity, setAnimateTokenomicsOpacity] = useAtom(
    Bottom.animateTokenomicsOpacityAtom
  );
  const [initialTokenomicsScale, setInitialTokenomicsScale] = useAtom(
    Bottom.initialTokenomicsScaleAtom
  );
  const [animateTokenomicsScale, setAnimateTokenomicsScale] = useAtom(
    Bottom.animateTokenomicsScaleAtom
  );

  const [initialTitleTop, setInitialTitleTop] = useAtom(
    Bottom.initialTitleTopAtom
  );
  const [animateTitleTop, setAnimateTitleTop] = useAtom(
    Bottom.animateTitleTopAtom
  );
  const [initialTitleLeft, setInitialTitleLeft] = useAtom(
    Bottom.initialTitleLeftAtom
  );
  const [animateTitleLeft, setAnimateTitleLeft] = useAtom(
    Bottom.animateTitleLeftAtom
  );
  const [initialTitleOpacity, setInitialTitleOpacity] = useAtom(
    Bottom.initialTitleOpacityAtom
  );
  const [animateTitleOpacity, setAnimateTitleOpacity] = useAtom(
    Bottom.animateTitleOpacityAtom
  );
  const [initialTitleScale, setInitialTitleScale] = useAtom(
    Bottom.initialTitleScaleAtom
  );
  const [animateTitleScale, setAnimateTitleScale] = useAtom(
    Bottom.animateTitleScaleAtom
  );

  const [flippedOne, setFlippedOne] = useState(false);
  const [flippedTwo, setFlippedTwo] = useState(false);

  const flipOne = () => {
    if (flippedOne === false) setFlippedOne(true);
    else if (flippedOne === true) setFlippedOne(false);
  };

  const flipTwo = () => {
    if (flippedTwo === false) setFlippedTwo(true);
    else if (flippedTwo === true) setFlippedTwo(false);
  };

  useEffect(() => {
    setSkyColor("#000000");
    setInitialBGOpacity(animateBGOpacity);
    setAnimateBGOpacity(1);
  });

  useEffect(() => {
    setInitialStarsTop(animateStarsTop);
    setInitialStarsLeft(animateStarsLeft);
    setInitialStarsOpacity(animateStarsOpacity);
    setAnimateStarsTop("-37.5px");
    setAnimateStarsLeft("-37.5px");
    setAnimateStarsOpacity(0.8);
    setInitialSunTop(animateSunTop);
    setInitialSunLeft(animateSunLeft);
    setAnimateSunTop("-300rem");
    setAnimateSunLeft("18rem");
  });

  useEffect(() => {
    setInitialTitleTop(animateTitleTop);
    setInitialTitleLeft(animateTitleLeft);
    setInitialTitleOpacity(animateTitleOpacity);
    setInitialTitleScale(animateTitleScale);

    setInitialMainBottom(animateMainBottom);
    setInitialMainLeft(animateMainLeft);
    setInitialMainOpacity(animateMainOpacity);
    setInitialMainScale(animateMainScale);
    setInitialMintBottom(animateMintBottom);
    setInitialMintLeft(animateMintLeft);
    setInitialMintOpacity(animateMintOpacity);
    setInitialMintScale(animateMintScale);
    setInitialTimelineBottom(animateTimelineBottom);
    setInitialTimelineLeft(animateTimelineLeft);
    setInitialTimelineOpacity(animateTimelineOpacity);
    setInitialTimelineScale(animateTimelineScale);
    setInitialAboutBottom(animateAboutBottom);
    setInitialAboutLeft(animateAboutLeft);
    setInitialAboutOpacity(animateAboutOpacity);
    setInitialAboutScale(animateAboutScale);
    setInitialFoundersBottom(animateFoundersBottom);
    setInitialFoundersLeft(animateFoundersLeft);
    setInitialFoundersOpacity(animateFoundersOpacity);
    setInitialFoundersScale(animateFoundersScale);
    setInitialVisionBottom(animateVisionBottom);
    setInitialVisionLeft(animateVisionLeft);
    setInitialVisionOpacity(animateVisionOpacity);
    setInitialVisionScale(animateVisionScale);
    setInitialTokenomicsBottom(animateTokenomicsBottom);
    setInitialTokenomicsLeft(animateTokenomicsLeft);
    setInitialTokenomicsOpacity(animateTokenomicsOpacity);
    setInitialTokenomicsScale(animateTokenomicsScale);

    setAnimateTitleTop("0vh");
    setAnimateTitleLeft("unset");
    setAnimateTitleOpacity(1);
    setAnimateTitleScale("scale(1)");

    setAnimateMainBottom("-20vh");
    setAnimateMainLeft("20vw");
    setAnimateMainOpacity(0);
    setAnimateMainScale("scale(1.5)");
    setAnimateMintBottom("-0vh");
    setAnimateMintLeft("-0vw");
    setAnimateMintOpacity(0);
    setAnimateMintScale("scale(1)");
    setAnimateTimelineBottom("-0vh");
    setAnimateTimelineLeft("-0vw");
    setAnimateTimelineOpacity(0);
    setAnimateTimelineScale("scale(1)");
    setAnimateAboutBottom("-0vh");
    setAnimateAboutLeft("-0vw");
    setAnimateAboutOpacity(0);
    setAnimateAboutScale("scale(1)");
    setAnimateFoundersBottom("-22vh");
    setAnimateFoundersLeft("-0vw");
    setAnimateFoundersOpacity(1);
    setAnimateFoundersScale("scale(1)");
    setAnimateVisionBottom("-0vh");
    setAnimateVisionLeft("-0vw");
    setAnimateVisionOpacity(0);
    setAnimateVisionScale("scale(1)");
    setAnimateTokenomicsBottom("-0vh");
    setAnimateTokenomicsLeft("-0vw");
    setAnimateTokenomicsOpacity(0);
    setAnimateTokenomicsScale("scale(1)");
  });

  return (
    <div className="RouteWrapper FoundersWrapper">
      <Top.TopBackground
        skyColorAtom={skyColor}
        initialBGOpacityAtom={initialBGOpacity}
        animateBGOpacityAtom={animateBGOpacity}
        initialStarsTopAtom={initialStarsTop}
        animateStarsTopAtom={animateStarsTop}
        initialStarsLeftAtom={initialStarsLeft}
        animateStarsLeftAtom={animateStarsLeft}
        initialStarsOpacityAtom={initialStarsOpacity}
        animateStarsOpacityAtom={animateStarsOpacity}
        initialSunTopAtom={initialSunTop}
        animateSunTopAtom={animateSunTop}
        initialSunLeftAtom={initialSunLeft}
        animateSunLeftAtom={animateSunLeft}
      />

      <div className="Base">
        {/* ##### VIEWPORT */}

        <div className="viewport">
          <div className="siteMap flx">
            <div className="breadcrumbs">
              <Link className="" to="/home">
                MCAA
              </Link>{" "}
              &nbsp; &#62; &nbsp;{" "}
              <Link className="" to="/founders">
                founders
              </Link>
            </div>
            <div className="next-bread">
              <Link className="" to="/vision">
                next: vision
              </Link>
            </div>
          </div>

          {/* ### CARD ### */}

          <motion.div
            className="container"
            initial={{ opacity: 0, top: "150%" }}
            animate={{ opacity: 1, top: "55%" }}
            transition={{ type: "tween", duration: 0.4 }}
          >
            <div>
              <h3>MCAA Founders</h3>

              <p>
                Our founding team is a combination of experienced crypto
                professionals, business professionals and small business growth
                finance specialists.
              </p>

              <div
                style={{
                  display: "flex",
                  columnGap: "2rem",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
                className="FoundersCards"
              >
                <div className="" onClick={() => flipOne()}>
                  <FlipCard style={{ overflow: "visible" }}>
                    <FrontCard
                      isCardFlipped={flippedOne}
                      style={{ position: "relative" }}
                    >
                      <img
                        className="responsive"
                        src="/NFT.jpg"
                        width="500"
                        height="500"
                        alt="MoneyMan Sam"
                      />
                      <div className="overlayCard">
                        <h2
                          className="button"
                          style={{
                            justifyContent: "center",
                            minWidth: "100%",
                            backgroundColor: "hsl(173deg 80% 30% / 66%)",
                            backgroundImage: "none",
                          }}
                        >
                          MoneyMan Sam
                        </h2>
                      </div>
                    </FrontCard>
                    <BackCard isCardFlipped={flippedOne}>
                      <div style={{ padding: 2 }}>
                        <p
                          style={{
                            padding: "0px 20px",
                            overflow: "scroll",
                            display: "block",
                          }}
                        >
                          MoneyMan Sam – Founder; is a serial entrepreneur and
                          thought leader, serving on the board of many companies
                          and as the CEO of Premium Merchant Funding. Sam’s
                          passion is in building businesses, connecting people
                          around growth opportunities in real estate, investing
                          and charitable works and supporting artistic
                          expression across a wide range of mediums, including
                          music, art and film. Sam is also an artist and enjoys
                          spending time traveling around the globe and
                          incorporating his life experiences into his artistic
                          expressions.
                        </p>
                      </div>
                    </BackCard>
                  </FlipCard>
                </div>

                <div className="" onClick={() => flipTwo()}>
                  <FlipCard>
                    <FrontCard
                      isCardFlipped={flippedTwo}
                      style={{ position: "relative" }}
                    >
                      <img
                        className="responsive"
                        src="/preview/0002.jpeg"
                        width="500"
                        height="500"
                        alt="MoneyMan Sam"
                      />
                      <div className="overlayCard">
                        <h2
                          className="button"
                          style={{
                            justifyContent: "center",
                            minWidth: "100%",
                            backgroundColor: "hsl(273deg 100% 65% / 66%)",
                            backgroundImage: "none",
                          }}
                        >
                          Abe Burger
                        </h2>
                      </div>
                    </FrontCard>
                    <BackCard isCardFlipped={flippedTwo}>
                      <div style={{ padding: 2 }}>
                        <p
                          style={{
                            padding: "0px 20px",
                            overflow: "scroll",
                            display: "block",
                          }}
                        >
                          Abe Burger - Founder; holds a JD for Fordham Law
                          School and has served as the Chief Operating Officer
                          of Premium Merchant Funding, an alternative finance
                          broker and funder, since 2014. In his role at MCAA,
                          Abe has developed underwriting guidelines, implemented
                          successful risk management policies and procedures to
                          manage a portfolio of alternative finance investments
                          and manages a brokerage which has provided over $1.5
                          Billion dollars in alternative financing to US and
                          Canadian small businesses. Abe is also the managing
                          member of a boutique hedge fund focused on debt and
                          equity investments in small and mid-sized businesses.
                        </p>
                      </div>
                    </BackCard>
                  </FlipCard>
                </div>
              </div>

              <br className="dt center" />

              <div className="dt center">
                <Link to="/roadmap">
                  <button className="button mx-auto">Roadmap</button>
                </Link>
              </div>

              <br className="dt center" />
            </div>
          </motion.div>
        </div>

        {/* ### BOTTOM BACKGROUND ### */}

        <motion.div
          className="BG transition"
          initial={{
            zIndex: 4,
            left: "150vw",
            right: "unset",
            top: "-20vh",
            opacity: 1,
            transform: "scale(0.85)",
          }}
          animate={{
            zIndex: 3,
            left: "-60vw",
            right: "100vw",
            top: "70vh",
            opacity: 1,
            transform: "scale(3.35)",
          }}
          transition={{ type: "tween", duration: 4, delay: 0 }}
        >
          <img src="/plane.png" alt="Train" className="Train" priority="true" />
        </motion.div>

        <Bottom.BottomBackground
          initialMainBottomAtom={initialMainBottom}
          animateMainBottomAtom={animateMainBottom}
          initialMainLeftAtom={initialMainLeft}
          animateMainLeftAtom={animateMainLeft}
          initialMainOpacityAtom={initialMainOpacity}
          animateMainOpacityAtom={animateMainOpacity}
          initialMainScaleAtom={initialMainScale}
          animateMainScaleAtom={animateMainScale}
          initialMintBottomAtom={initialMintBottom}
          animateMintBottomAtom={animateMintBottom}
          initialMintLeftAtom={initialMintLeft}
          animateMintLeftAtom={animateMintLeft}
          initialMintOpacityAtom={initialMintOpacity}
          animateMintOpacityAtom={animateMintOpacity}
          initialMintScaleAtom={initialMintScale}
          animateMintScaleAtom={animateMintScale}
          initialTimelineBottomAtom={initialTimelineBottom}
          animateTimelineBottomAtom={animateTimelineBottom}
          initialTimelineLeftAtom={initialTimelineLeft}
          animateTimelineLeftAtom={animateTimelineLeft}
          initialTimelineOpacityAtom={initialTimelineOpacity}
          animateTimelineOpacityAtom={animateTimelineOpacity}
          initialTimelineScaleAtom={initialTimelineScale}
          animateTimelineScaleAtom={animateTimelineScale}
          initialAboutBottomAtom={initialAboutBottom}
          animateAboutBottomAtom={animateAboutBottom}
          initialAboutLeftAtom={initialAboutLeft}
          animateAboutLeftAtom={animateAboutLeft}
          initialAboutOpacityAtom={initialAboutOpacity}
          animateAboutOpacityAtom={animateAboutOpacity}
          initialAboutScaleAtom={initialAboutScale}
          animateAboutScaleAtom={animateAboutScale}
          initialFoundersBottomAtom={initialFoundersBottom}
          animateFoundersBottomAtom={animateFoundersBottom}
          initialFoundersLeftAtom={initialFoundersLeft}
          animateFoundersLeftAtom={animateFoundersLeft}
          initialFoundersOpacityAtom={initialFoundersOpacity}
          animateFoundersOpacityAtom={animateFoundersOpacity}
          initialFoundersScaleAtom={initialFoundersScale}
          animateFoundersScaleAtom={animateFoundersScale}
          initialVisionBottomAtom={initialVisionBottom}
          animateVisionBottomAtom={animateVisionBottom}
          initialVisionLeftAtom={initialVisionLeft}
          animateVisionLeftAtom={animateVisionLeft}
          initialVisionOpacityAtom={initialVisionOpacity}
          animateVisionOpacityAtom={animateVisionOpacity}
          initialVisionScaleAtom={initialVisionScale}
          animateVisionScaleAtom={animateVisionScale}
          initialTokenomicsBottomAtom={initialTokenomicsBottom}
          animateTokenomicsBottomAtom={animateTokenomicsBottom}
          initialTokenomicsLeftAtom={initialTokenomicsLeft}
          animateTokenomicsLeftAtom={animateTokenomicsLeft}
          initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
          animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
          initialTokenomicsScaleAtom={initialTokenomicsScale}
          animateTokenomicsScaleAtom={animateTokenomicsScale}
          initialTitleTopAtom={initialTitleTop}
          animateTitleTopAtom={animateTitleTop}
          initialTitleLeftAtom={initialTitleLeft}
          animateTitleLeftAtom={animateTitleLeft}
          initialTitleOpacityAtom={initialTitleOpacity}
          animateTitleOpacityAtom={animateTitleOpacity}
          initialTitleScaleAtom={initialTitleScale}
          animateTitleScaleAtom={animateTitleScale}
        />
      </div>
    </div>
  );
};

export default Founders;
