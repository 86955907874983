import React from "react";
import {  Image } from "antd";


function SecondFiles() {
  const link = "https://gateway.pinata.cloud/ipfs/"
  return (
    <div class="gallery">
      <Image
        className="responsive"
        src={`${link}QmYrioP4j1g7WGFw5XRPDXvpPL915CohXfi2rA83A5p5Yh`}
        width={250}
        // height="500"
        alt="075"
      />
      <Image
        className="responsive"
        src={`${link}QmcPAbrGdepLvahd96vVhNmZtYFtYo8j6RPQR9LMKSh3Ej`}
        width={250}
        // height="500"
        alt="028"
      />
      <Image
        className="responsive"
        src={`${link}QmcSZk6ZxVKEeUs5QsuZDn5RMXdtKE3mFyAadtEBeqnapa`}
        width={250}
        // height="500"
        alt="036"
      />
      <Image
        className="responsive"
        src={`${link}QmT1uyRikwiBqhN7uB9TSDdYUQzFis4UxZ1pZb6tt8mwxG`}
        width={250}
        // height="500"
        alt="004"
      />
      <Image
        className="responsive"
        src={`${link}QmZridv9ebAD9WtWkdPeV7Uf2jfUmS1sEH17nubNpausEs`}
        width={250}
        // height="500"
        alt="011"
      />
      <Image
        className="responsive"
        src={`${link}QmcZ73ix6TkzUEiKhktD6kcJHotWp1sQQNRmq9CphQQiKB`}
        width={250}
        // height="500"
        alt="015"
      />
      <Image
        className="responsive"
        src={`${link}QmbzFpkzrNNUraPBf8htTpzhEUNMAAdK8vnVPyanaXu3mq`}
        width={250}
        // height="500"
        alt="041"
      />
      <Image
        className="responsive"
        src={`${link}QmQVQZzWmN2agXXJensAu4k5nJGXuWJzFT4TABeQyAUoTQ`}
        width={250}
        // height="500"
        alt="072"
      />
      <Image
        className="responsive"
        src={`${link}QmVuQvJbKqd26oGsxaG3dd8X4Q3hJZJ2pLe27AAeLkxAej`}
        width={250}
        // height="500"
        alt="076"
      />
      <Image
        className="responsive"
        src={`${link}QmUNNqCPKdUhvwgFscH4U5KEy1tMqFReQy3xDRm6mFEfzw`}
        width={250}
        // height="500"
        alt="076"
      />
      <Image
        className="responsive"
        src={`${link}QmXs3v5fKPBvyGrtTqSXvB2pC8Cyic2SCs6C4Jic4nqe56`}
        width={250}
        // height="500"
        alt="076"
      />
      <Image
        className="responsive"
        src={`${link}QmdZbjr68CkztPgteiJkEnMxHRpD6v6CVvC16HU8MotTvU`}
        width={250}
        // height="500"
        alt="076"
      />
      <Image
        className="responsive"
        src={`${link}QmeRQaprgveABLYwcv1etYgGTqMrYqpZddjQjb78Y3XHpe`}
        width={250}
        // height="500"
        alt="076"
      />
      <Image
        className="responsive"
        src={`${link}QmS4gUoUDMn84EFFimtkRRf7Y7PppJ12PoKB3ZgMzXrd6Q`}
        width={250}
        // height="500"
        alt="076"
      />
      <Image
        className="responsive"
        src={`${link}QmVZHmywmUniShaj1diySkho1j38wXwh6U4tij9A9Y5qfq`}
        width={250}
        // height="500"
        alt="076"
      />
      <Image
        className="responsive"
        src={`${link}QmPz6FyevyXRaY1yYFTeroqpKxM5AhEL91CXWKsHybQjUa`}
        width={250}
        // height="500"
        alt="076"
      />
    </div>
  );
}

export default SecondFiles;
