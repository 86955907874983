import React from 'react';
import p004 from 'assets/preview/004.jpg';
import p011 from 'assets/preview/011.jpg';
import p015 from 'assets/preview/015.jpg';
import p028 from 'assets/preview/028.jpg';
import p036 from 'assets/preview/036.jpg';
import p041 from 'assets/preview/041.jpg';
import p072 from 'assets/preview/072.jpg';
import p075 from 'assets/preview/075.jpg';
import p076 from 'assets/preview/076.jpg';

import i0 from 'assets/updated_images/image (0).jpeg';
import i1 from 'assets/updated_images/image (1).jpeg';
import i2 from 'assets/updated_images/image (2).jpeg';
import i3 from 'assets/updated_images/image (3).jpeg';
import i4 from 'assets/updated_images/image (4).jpeg';
import i5 from 'assets/updated_images/image (5).jpeg';
import i6 from 'assets/updated_images/image (6).jpeg';
import i7 from 'assets/updated_images/image (7).jpeg';
import i8 from 'assets/updated_images/image (8).jpeg';
import i9 from 'assets/updated_images/image (9).jpeg';
import i10 from 'assets/updated_images/image (10).jpeg';
import i11 from 'assets/updated_images/image (11).jpeg';
import i12 from 'assets/updated_images/image (12).jpeg';
import i13 from 'assets/updated_images/image (13).jpeg';
import i14 from 'assets/updated_images/image (14).jpeg';
import i15 from 'assets/updated_images/image (15).jpeg';
import i16 from 'assets/updated_images/image (16).jpeg';
import i17 from 'assets/updated_images/image (17).jpeg';
import i18 from 'assets/updated_images/image (18).jpeg';
import i19 from 'assets/updated_images/image (19).jpeg';
import i20 from 'assets/updated_images/image (20).jpeg';
import i21 from 'assets/updated_images/image (21).jpeg';
import i22 from 'assets/updated_images/image (22).jpeg';
import i23 from 'assets/updated_images/image (23).jpeg';
import i24 from 'assets/updated_images/image (24).jpeg';
import i25 from 'assets/updated_images/image (25).jpeg';
import i26 from 'assets/updated_images/image (26).jpeg';
import i27 from 'assets/updated_images/image (27).jpeg';
import i28 from 'assets/updated_images/image (28).jpeg';
import i29 from 'assets/updated_images/image (29).jpeg';
import i30 from 'assets/updated_images/image (30).jpeg';
import i31 from 'assets/updated_images/image (31).jpeg';
import i32 from 'assets/updated_images/image (32).jpeg';
import i33 from 'assets/updated_images/image (33).jpeg';
import i34 from 'assets/updated_images/image (34).jpeg';
import i35 from 'assets/updated_images/image (35).jpeg';
import i36 from 'assets/updated_images/image (36).jpeg';
import i37 from 'assets/updated_images/image (37).jpeg';
import i38 from 'assets/updated_images/image (38).jpeg';
import i39 from 'assets/updated_images/image (39).jpeg';
import i40 from 'assets/updated_images/image (40).jpeg';
import i41 from 'assets/updated_images/image (41).jpeg';
import i42 from 'assets/updated_images/image (42).jpeg';
import i43 from 'assets/updated_images/image (43).jpeg';
import i44 from 'assets/updated_images/image (44).jpeg';
import i45 from 'assets/updated_images/image (45).jpeg';
import i46 from 'assets/updated_images/image (46).jpeg';
import i47 from 'assets/updated_images/image (47).jpeg';
import i48 from 'assets/updated_images/image (48).jpeg';
import i49 from 'assets/updated_images/image (49).jpeg';
import i50 from 'assets/updated_images/image (50).jpeg';
import i51 from 'assets/updated_images/image (51).jpeg';
import i52 from 'assets/updated_images/image (52).jpeg';
import i53 from 'assets/updated_images/image (53).jpeg';
import i54 from 'assets/updated_images/image (54).jpeg';
import i55 from 'assets/updated_images/image (55).jpeg';
import i56 from 'assets/updated_images/image (56).jpeg';
import i57 from 'assets/updated_images/image (57).jpeg';
import i58 from 'assets/updated_images/image (58).jpeg';
import i59 from 'assets/updated_images/image (59).jpeg';
import i60 from 'assets/updated_images/image (60).jpeg';
import i61 from 'assets/updated_images/image (61).jpeg';
import i62 from 'assets/updated_images/image (62).jpeg';
import i63 from 'assets/updated_images/image (63).jpeg';
import i64 from 'assets/updated_images/image (64).jpeg';
import i65 from 'assets/updated_images/image (65).jpeg';
import i66 from 'assets/updated_images/image (66).jpeg';
import i67 from 'assets/updated_images/image (67).jpeg';
import i68 from 'assets/updated_images/image (68).jpeg';
import i69 from 'assets/updated_images/image (69).jpeg';
import i70 from 'assets/updated_images/image (70).jpeg';
import i71 from 'assets/updated_images/image (71).jpeg';
import i72 from 'assets/updated_images/image (72).jpeg';
import i73 from 'assets/updated_images/image (73).jpeg';
import i74 from 'assets/updated_images/image (74).jpeg';
import i75 from 'assets/updated_images/image (75).jpeg';
import i76 from 'assets/updated_images/image (76).jpeg';
import i77 from 'assets/updated_images/image (77).jpeg';
import i78 from 'assets/updated_images/image (78).jpeg';
import i79 from 'assets/updated_images/image (79).jpeg';
import i80 from 'assets/updated_images/image (80).jpeg';
import i81 from 'assets/updated_images/image (81).jpeg';
import i82 from 'assets/updated_images/image (82).jpeg';
import i83 from 'assets/updated_images/image (83).jpeg';
import i84 from 'assets/updated_images/image (84).jpeg';
import i85 from 'assets/updated_images/image (85).jpeg';
import i86 from 'assets/updated_images/image (86).jpeg';
import i87 from 'assets/updated_images/image (87).jpeg';
import i88 from 'assets/updated_images/image (88).jpeg';
import i89 from 'assets/updated_images/image (89).jpeg';
import i90 from 'assets/updated_images/image (90).jpeg';
import i91 from 'assets/updated_images/image (91).jpeg';
import i92 from 'assets/updated_images/image (92).jpeg';
import i93 from 'assets/updated_images/image (93).jpeg';
import i94 from 'assets/updated_images/image (94).jpeg';
import i95 from 'assets/updated_images/image (95).jpeg';
import i96 from 'assets/updated_images/image (96).jpeg';
import i97 from 'assets/updated_images/image (97).jpeg';
import i98 from 'assets/updated_images/image (98).jpeg';
import i99 from 'assets/updated_images/image (99).jpeg';
import i100 from 'assets/updated_images/image (100).jpeg';



import {  Image } from "antd";
{/* <Image width={100} src={`${API_URL}/gallery/${item?.image}`} /> */}

function Files() {
    
    return (
        <div class="gallery">
            <Image className='responsive' width={250} src={i0} />
            <Image className='responsive' width={250} src={i1} />
            <Image className='responsive' width={250} src={i2} />
            <Image className='responsive' width={250} src={i3} />
            <Image className='responsive' width={250} src={i4} />
            <Image className='responsive' width={250} src={i5} />
            <Image className='responsive' width={250} src={i6} />
            <Image className='responsive' width={250} src={i7} />
            <Image className='responsive' width={250} src={i8} />
            <Image className='responsive' width={250} src={i9} />
            <Image className='responsive' width={250} src={i10} />
            <Image className='responsive' width={250} src={i11} />
            <Image className='responsive' width={250} src={i12} />
            <Image className='responsive' width={250} src={i13} />
            <Image className='responsive' width={250} src={i14} />
            <Image className='responsive' width={250} src={i15} />
            <Image className='responsive' width={250} src={i16} />
            <Image className='responsive' width={250} src={i17} />
            <Image className='responsive' width={250} src={i18} />
            <Image className='responsive' width={250} src={i19} />
            <Image className='responsive' width={250} src={i20} />
            <Image className='responsive' width={250} src={i21} />
            <Image className='responsive' width={250} src={i22} />
            <Image className='responsive' width={250} src={i23} />
            <Image className='responsive' width={250} src={i24} />
            <Image className='responsive' width={250} src={i25} />
            <Image className='responsive' width={250} src={i26} />
            <Image className='responsive' width={250} src={i27} />
            <Image className='responsive' width={250} src={i28} />
            <Image className='responsive' width={250} src={i29} />
            <Image className='responsive' width={250} src={i30} />
            <Image className='responsive' width={250} src={i31} />
            <Image className='responsive' width={250} src={i32} />
            <Image className='responsive' width={250} src={i33} />
            <Image className='responsive' width={250} src={i34} />
            <Image className='responsive' width={250} src={i35} />
            <Image className='responsive' width={250} src={i36} />
            <Image className='responsive' width={250} src={i37} />
            <Image className='responsive' width={250} src={i38} />
            <Image className='responsive' width={250} src={i39} />
            <Image className='responsive' width={250} src={i40} />
            <Image className='responsive' width={250} src={i41} />
            <Image className='responsive' width={250} src={i42} />
            <Image className='responsive' width={250} src={i43} />
            <Image className='responsive' width={250} src={i44} />
            <Image className='responsive' width={250} src={i45} />
            <Image className='responsive' width={250} src={i46} />
            <Image className='responsive' width={250} src={i47} />
            <Image className='responsive' width={250} src={i48} />
            <Image className='responsive' width={250} src={i49} />
            <Image className='responsive' width={250} src={i50} />
            <Image className='responsive' width={250} src={i51} />
            <Image className='responsive' width={250} src={i52} />
            <Image className='responsive' width={250} src={i53} />
            <Image className='responsive' width={250} src={i54} />
            <Image className='responsive' width={250} src={i55} />
            <Image className='responsive' width={250} src={i56} />
            <Image className='responsive' width={250} src={i57} />
            <Image className='responsive' width={250} src={i58} />
            <Image className='responsive' width={250} src={i59} />
            <Image className='responsive' width={250} src={i60} />
            <Image className='responsive' width={250} src={i61} />
            <Image className='responsive' width={250} src={i62} />
            <Image className='responsive' width={250} src={i63} />
            <Image className='responsive' width={250} src={i64} />
            <Image className='responsive' width={250} src={i65} />
            <Image className='responsive' width={250} src={i66} />
            <Image className='responsive' width={250} src={i67} />
            <Image className='responsive' width={250} src={i68} />
            <Image className='responsive' width={250} src={i69} />
            <Image className='responsive' width={250} src={i70} />
            <Image className='responsive' width={250} src={i71} />
            <Image className='responsive' width={250} src={i72} />
            <Image className='responsive' width={250} src={i73} />
            <Image className='responsive' width={250} src={i74} />
            <Image className='responsive' width={250} src={i75} />
            <Image className='responsive' width={250} src={i76} />
            <Image className='responsive' width={250} src={i77} />
            <Image className='responsive' width={250} src={i78} />
            <Image className='responsive' width={250} src={i79} />
            <Image className='responsive' width={250} src={i80} />
            <Image className='responsive' width={250} src={i81} />
            <Image className='responsive' width={250} src={i82} />
            <Image className='responsive' width={250} src={i83} />
            <Image className='responsive' width={250} src={i84} />
            <Image className='responsive' width={250} src={i85} />
            <Image className='responsive' width={250} src={i86} />
            <Image className='responsive' width={250} src={i87} />
            <Image className='responsive' width={250} src={i88} />
            <Image className='responsive' width={250} src={i89} />
            <Image className='responsive' width={250} src={i90} />
            <Image className='responsive' width={250} src={i91} />
            <Image className='responsive' width={250} src={i92} />
            <Image className='responsive' width={250} src={i93} />
            <Image className='responsive' width={250} src={i94} />
            <Image className='responsive' width={250} src={i95} />
            <Image className='responsive' width={250} src={i96} />
            <Image className='responsive' width={250} src={i97} />
            <Image className='responsive' width={250} src={i98} />
            <Image className='responsive' width={250} src={i99} />
            <Image className='responsive' width={250} src={i100} />
            


            {/* <Image className='responsive' width={250} src={p028} />
            <Image className='responsive' width={250} src={p036} />
            <Image className='responsive' width={250} src={p004} />
            <Image className='responsive' width={250} src={p011} />
            <Image className='responsive' width={250} src={p015} />
            <Image className='responsive' width={250} src={p041} />
            <Image className='responsive' width={250} src={p072} />
            <Image className='responsive' width={250} src={p076} /> */}
            {/* <img className='responsive' src={p075}  width='500' height='500' alt='075' />
            <img className='responsive' src={p028}  width='500' height='500' alt='028' />
            <img className='responsive' src={p036}  width='500' height='500' alt='036' />
            <img className='responsive' src={p004}  width='500' height='500' alt='004' />           
            <img className='responsive' src={p011}  width='500' height='500' alt='011' />
            <img className='responsive' src={p015}  width='500' height='500' alt='015' />            
            <img className='responsive' src={p041}  width='500' height='500' alt='041' />
            <img className='responsive' src={p072}  width='500' height='500' alt='072' />
            <img className='responsive' src={p076}  width='500' height='500' alt='076' /> */}
        </div>
    );
}

export default Files;