import React, { useRef, useEffect } from 'react';
import {  Image } from "antd";

function ThirdFiles() {

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    return (
        <div class="">
            {/* <img className='' src="/community-token.gif" height='400' alt='075' style={{maxWidth: '300px'}} /> */}
            <Image className='' src={"/community-token.gif"} width={250} />
        </div>
    );
}

export default ThirdFiles;