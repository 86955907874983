import { useEffect } from "react";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import * as Top from "components/backgrounds/TopBackground";
import * as Bottom from "components/backgrounds/BottomBackground";
import "styles/modal.css";

const Tokenomics = () => {
  // some 1
  const { isConnected } = useAccount();

  const [skyColor, setSkyColor] = useAtom(Top.skyColorAtom);
  const [initialBGOpacity, setInitialBGOpacity] = useAtom(
    Top.initialBGOpacityAtom
  );
  const [animateBGOpacity, setAnimateBGOpacity] = useAtom(
    Top.animateBGOpacityAtom
  );
  const [initialStarsTop, setInitialStarsTop] = useAtom(
    Top.initialStarsTopAtom
  );
  const [animateStarsTop, setAnimateStarsTop] = useAtom(
    Top.animateStarsTopAtom
  );
  const [initialStarsLeft, setInitialStarsLeft] = useAtom(
    Top.initialStarsLeftAtom
  );
  const [animateStarsLeft, setAnimateStarsLeft] = useAtom(
    Top.animateStarsLeftAtom
  );
  const [initialStarsOpacity, setInitialStarsOpacity] = useAtom(
    Top.initialStarsOpacityAtom
  );
  const [animateStarsOpacity, setAnimateStarsOpacity] = useAtom(
    Top.animateStarsOpacityAtom
  );
  const [initialSunTop, setInitialSunTop] = useAtom(Top.initialSunTopAtom);
  const [animateSunTop, setAnimateSunTop] = useAtom(Top.animateSunTopAtom);
  const [initialSunLeft, setInitialSunLeft] = useAtom(Top.initialSunLeftAtom);
  const [animateSunLeft, setAnimateSunLeft] = useAtom(Top.animateSunLeftAtom);

  const [initialMainBottom, setInitialMainBottom] = useAtom(
    Bottom.initialMainBottomAtom
  );
  const [animateMainBottom, setAnimateMainBottom] = useAtom(
    Bottom.animateMainBottomAtom
  );
  const [initialMainLeft, setInitialMainLeft] = useAtom(
    Bottom.initialMainLeftAtom
  );
  const [animateMainLeft, setAnimateMainLeft] = useAtom(
    Bottom.animateMainLeftAtom
  );
  const [initialMainOpacity, setInitialMainOpacity] = useAtom(
    Bottom.initialMainOpacityAtom
  );
  const [animateMainOpacity, setAnimateMainOpacity] = useAtom(
    Bottom.animateMainOpacityAtom
  );
  const [initialMainScale, setInitialMainScale] = useAtom(
    Bottom.initialMainScaleAtom
  );
  const [animateMainScale, setAnimateMainScale] = useAtom(
    Bottom.animateMainScaleAtom
  );
  const [initialMintBottom, setInitialMintBottom] = useAtom(
    Bottom.initialMintBottomAtom
  );
  const [animateMintBottom, setAnimateMintBottom] = useAtom(
    Bottom.animateMintBottomAtom
  );
  const [initialMintLeft, setInitialMintLeft] = useAtom(
    Bottom.initialMintLeftAtom
  );
  const [animateMintLeft, setAnimateMintLeft] = useAtom(
    Bottom.animateMintLeftAtom
  );
  const [initialMintOpacity, setInitialMintOpacity] = useAtom(
    Bottom.initialMintOpacityAtom
  );
  const [animateMintOpacity, setAnimateMintOpacity] = useAtom(
    Bottom.animateMintOpacityAtom
  );
  const [initialMintScale, setInitialMintScale] = useAtom(
    Bottom.initialMintScaleAtom
  );
  const [animateMintScale, setAnimateMintScale] = useAtom(
    Bottom.animateMintScaleAtom
  );
  const [initialTimelineBottom, setInitialTimelineBottom] = useAtom(
    Bottom.initialTimelineBottomAtom
  );
  const [animateTimelineBottom, setAnimateTimelineBottom] = useAtom(
    Bottom.animateTimelineBottomAtom
  );
  const [initialTimelineLeft, setInitialTimelineLeft] = useAtom(
    Bottom.initialTimelineLeftAtom
  );
  const [animateTimelineLeft, setAnimateTimelineLeft] = useAtom(
    Bottom.animateTimelineLeftAtom
  );
  const [initialTimelineOpacity, setInitialTimelineOpacity] = useAtom(
    Bottom.initialTimelineOpacityAtom
  );
  const [animateTimelineOpacity, setAnimateTimelineOpacity] = useAtom(
    Bottom.animateTimelineOpacityAtom
  );
  const [initialTimelineScale, setInitialTimelineScale] = useAtom(
    Bottom.initialTimelineScaleAtom
  );
  const [animateTimelineScale, setAnimateTimelineScale] = useAtom(
    Bottom.animateTimelineScaleAtom
  );
  const [initialAboutBottom, setInitialAboutBottom] = useAtom(
    Bottom.initialAboutBottomAtom
  );
  const [animateAboutBottom, setAnimateAboutBottom] = useAtom(
    Bottom.animateAboutBottomAtom
  );
  const [initialAboutLeft, setInitialAboutLeft] = useAtom(
    Bottom.initialAboutLeftAtom
  );
  const [animateAboutLeft, setAnimateAboutLeft] = useAtom(
    Bottom.animateAboutLeftAtom
  );
  const [initialAboutOpacity, setInitialAboutOpacity] = useAtom(
    Bottom.initialAboutOpacityAtom
  );
  const [animateAboutOpacity, setAnimateAboutOpacity] = useAtom(
    Bottom.animateAboutOpacityAtom
  );
  const [initialAboutScale, setInitialAboutScale] = useAtom(
    Bottom.initialAboutScaleAtom
  );
  const [animateAboutScale, setAnimateAboutScale] = useAtom(
    Bottom.animateAboutScaleAtom
  );
  const [initialFoundersBottom, setInitialFoundersBottom] = useAtom(
    Bottom.initialFoundersBottomAtom
  );
  const [animateFoundersBottom, setAnimateFoundersBottom] = useAtom(
    Bottom.animateFoundersBottomAtom
  );
  const [initialFoundersLeft, setInitialFoundersLeft] = useAtom(
    Bottom.initialFoundersLeftAtom
  );
  const [animateFoundersLeft, setAnimateFoundersLeft] = useAtom(
    Bottom.animateFoundersLeftAtom
  );
  const [initialFoundersOpacity, setInitialFoundersOpacity] = useAtom(
    Bottom.initialFoundersOpacityAtom
  );
  const [animateFoundersOpacity, setAnimateFoundersOpacity] = useAtom(
    Bottom.animateFoundersOpacityAtom
  );
  const [initialFoundersScale, setInitialFoundersScale] = useAtom(
    Bottom.initialFoundersScaleAtom
  );
  const [animateFoundersScale, setAnimateFoundersScale] = useAtom(
    Bottom.animateFoundersScaleAtom
  );
  const [initialVisionBottom, setInitialVisionBottom] = useAtom(
    Bottom.initialVisionBottomAtom
  );
  const [animateVisionBottom, setAnimateVisionBottom] = useAtom(
    Bottom.animateVisionBottomAtom
  );
  const [initialVisionLeft, setInitialVisionLeft] = useAtom(
    Bottom.initialVisionLeftAtom
  );
  const [animateVisionLeft, setAnimateVisionLeft] = useAtom(
    Bottom.animateVisionLeftAtom
  );
  const [initialVisionOpacity, setInitialVisionOpacity] = useAtom(
    Bottom.initialVisionOpacityAtom
  );
  const [animateVisionOpacity, setAnimateVisionOpacity] = useAtom(
    Bottom.animateVisionOpacityAtom
  );
  const [initialVisionScale, setInitialVisionScale] = useAtom(
    Bottom.initialVisionScaleAtom
  );
  const [animateVisionScale, setAnimateVisionScale] = useAtom(
    Bottom.animateVisionScaleAtom
  );
  const [initialTokenomicsBottom, setInitialTokenomicsBottom] = useAtom(
    Bottom.initialTokenomicsBottomAtom
  );
  const [animateTokenomicsBottom, setAnimateTokenomicsBottom] = useAtom(
    Bottom.animateTokenomicsBottomAtom
  );
  const [initialTokenomicsLeft, setInitialTokenomicsLeft] = useAtom(
    Bottom.initialTokenomicsLeftAtom
  );
  const [animateTokenomicsLeft, setAnimateTokenomicsLeft] = useAtom(
    Bottom.animateTokenomicsLeftAtom
  );
  const [initialTokenomicsOpacity, setInitialTokenomicsOpacity] = useAtom(
    Bottom.initialTokenomicsOpacityAtom
  );
  const [animateTokenomicsOpacity, setAnimateTokenomicsOpacity] = useAtom(
    Bottom.animateTokenomicsOpacityAtom
  );
  const [initialTokenomicsScale, setInitialTokenomicsScale] = useAtom(
    Bottom.initialTokenomicsScaleAtom
  );
  const [animateTokenomicsScale, setAnimateTokenomicsScale] = useAtom(
    Bottom.animateTokenomicsScaleAtom
  );

  const [initialTitleTop, setInitialTitleTop] = useAtom(
    Bottom.initialTitleTopAtom
  );
  const [animateTitleTop, setAnimateTitleTop] = useAtom(
    Bottom.animateTitleTopAtom
  );
  const [initialTitleLeft, setInitialTitleLeft] = useAtom(
    Bottom.initialTitleLeftAtom
  );
  const [animateTitleLeft, setAnimateTitleLeft] = useAtom(
    Bottom.animateTitleLeftAtom
  );
  const [initialTitleOpacity, setInitialTitleOpacity] = useAtom(
    Bottom.initialTitleOpacityAtom
  );
  const [animateTitleOpacity, setAnimateTitleOpacity] = useAtom(
    Bottom.animateTitleOpacityAtom
  );
  const [initialTitleScale, setInitialTitleScale] = useAtom(
    Bottom.initialTitleScaleAtom
  );
  const [animateTitleScale, setAnimateTitleScale] = useAtom(
    Bottom.animateTitleScaleAtom
  );

  useEffect(() => {
    setSkyColor("#288fe3");
    setInitialBGOpacity(animateBGOpacity);
    setAnimateBGOpacity(0);
  });

  useEffect(() => {
    setInitialStarsTop(animateStarsTop);
    setInitialStarsLeft(animateStarsLeft);
    setInitialStarsOpacity(animateStarsOpacity);
    setAnimateStarsTop("0px");
    setAnimateStarsLeft("0px");
    setAnimateStarsOpacity(0);
    setInitialSunTop(animateSunTop);
    setInitialSunLeft(animateSunLeft);
    setAnimateSunTop("15rem");
    setAnimateSunLeft("16rem");
  });

  useEffect(() => {
    setInitialTitleTop(animateTitleTop);
    setInitialTitleLeft(animateTitleLeft);
    setInitialTitleOpacity(animateTitleOpacity);
    setInitialTitleScale(animateTitleScale);

    setInitialMainBottom(animateMainBottom);
    setInitialMainLeft(animateMainLeft);
    setInitialMainOpacity(animateMainOpacity);
    setInitialMainScale(animateMainScale);
    setInitialMintBottom(animateMintBottom);
    setInitialMintLeft(animateMintLeft);
    setInitialMintOpacity(animateMintOpacity);
    setInitialMintScale(animateMintScale);
    setInitialTimelineBottom(animateTimelineBottom);
    setInitialTimelineLeft(animateTimelineLeft);
    setInitialTimelineOpacity(animateTimelineOpacity);
    setInitialTimelineScale(animateTimelineScale);
    setInitialAboutBottom(animateAboutBottom);
    setInitialAboutLeft(animateAboutLeft);
    setInitialAboutOpacity(animateAboutOpacity);
    setInitialAboutScale(animateAboutScale);
    setInitialFoundersBottom(animateFoundersBottom);
    setInitialFoundersLeft(animateFoundersLeft);
    setInitialFoundersOpacity(animateFoundersOpacity);
    setInitialFoundersScale(animateFoundersScale);
    setInitialVisionBottom(animateVisionBottom);
    setInitialVisionLeft(animateVisionLeft);
    setInitialVisionOpacity(animateVisionOpacity);
    setInitialVisionScale(animateVisionScale);
    setInitialTokenomicsBottom(animateTokenomicsBottom);
    setInitialTokenomicsLeft(animateTokenomicsLeft);
    setInitialTokenomicsOpacity(animateTokenomicsOpacity);
    setInitialTokenomicsScale(animateTokenomicsScale);

    setAnimateTitleTop("0vh");
    setAnimateTitleLeft("unset");
    setAnimateTitleOpacity(1);
    setAnimateTitleScale("scale(1)");

    setAnimateMainBottom("-20vh");
    setAnimateMainLeft("-20vw");
    setAnimateMainOpacity(0);
    setAnimateMainScale("scale(1.5)");
    setAnimateMintBottom("-0vh");
    setAnimateMintLeft("-0vw");
    setAnimateMintOpacity(0);
    setAnimateMintScale("scale(1)");
    setAnimateTimelineBottom("-0vh");
    setAnimateTimelineLeft("-0vw");
    setAnimateTimelineOpacity(0);
    setAnimateTimelineScale("scale(1)");
    setAnimateAboutBottom("-0vh");
    setAnimateAboutLeft("-0vw");
    setAnimateAboutOpacity(0);
    setAnimateAboutScale("scale(1)");
    setAnimateFoundersBottom("-0vh");
    setAnimateFoundersLeft("-0vw");
    setAnimateFoundersOpacity(0);
    setAnimateFoundersScale("scale(1)");
    setAnimateVisionBottom("-0vh");
    setAnimateVisionLeft("-0vw");
    setAnimateVisionOpacity(0);
    setAnimateVisionScale("scale(1)");
    setAnimateTokenomicsBottom("-0vh");
    setAnimateTokenomicsLeft("-0vw");
    setAnimateTokenomicsOpacity(1);
    setAnimateTokenomicsScale("scale(1.2)");
  });

  return (
    <div className="RouteWrapper TokenomicsWrapper">
      {/* ### SKY BACKGROUND ### */}

      <Top.TopBackground
        skyColorAtom={skyColor}
        initialBGOpacityAtom={initialBGOpacity}
        animateBGOpacityAtom={animateBGOpacity}
        initialStarsTopAtom={initialStarsTop}
        animateStarsTopAtom={animateStarsTop}
        initialStarsLeftAtom={initialStarsLeft}
        animateStarsLeftAtom={animateStarsLeft}
        initialStarsOpacityAtom={initialStarsOpacity}
        animateStarsOpacityAtom={animateStarsOpacity}
        initialSunTopAtom={initialSunTop}
        animateSunTopAtom={animateSunTop}
        initialSunLeftAtom={initialSunLeft}
        animateSunLeftAtom={animateSunLeft}
      />

      <div className="Base Tokenomics">
        {/* ##### VIEWPORT */}

        <div className="viewport">
          <div className="siteMap flx">
            <div className="breadcrumbs">
              <Link to="/">MCAA</Link> &nbsp; &#62; &nbsp;{" "}
              <Link className="" to="/tokenomics">
                tokenomics
              </Link>
            </div>
            <div className="next-bread">
              <Link to="/mint">LAST: MINT</Link>
            </div>
          </div>

          {/* ### CARD ### */}

          <motion.div
            style={{ overflow: "scroll" }}
            className="container"
            initial={{ opacity: 0, top: "150%" }}
            animate={{ opacity: 1, top: "50%" }}
            transition={{ type: "tween", duration: 0.4 }}
          >
            <div style={{ overflow: "auto" }}>
              <h3>Tokenomics</h3>
              <p>
                <a href="/operating-agreement.pdf" target="_blank">
                  <u>Operating agreement</u>
                </a>
              </p>
              <p>
                <b>Buddha Boy PMF DAO LLC NFT Ownership and Utility Contract</b>
              </p>
              <p>
                <b>Article 1: Introduction</b>
              </p>
              <ul>
                <li>
                  This contract governs the issuance and rights associated with
                  Non-Fungible Tokens (NFTs) in Buddha Boy PMF DAO LLC ("the
                  DAO"). These NFTs provide voting rights, and access to a range
                  of services and events, as detailed below.
                </li>
              </ul>

              <p>
                <b>
                  Article 2: Token Categories, Percentage of DAO Distribution,
                  Art Access, and Benefits
                </b>
              </p>


              {/* <ul>
                <li>Section 2.1: Token Types and Benefits</li>

                <li>
                  <ul>
                    <li>1. Owners PMF Tokens (2 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> Full.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Full access to all DAO
                        utilities, events, exclusive Buddha Boy artwork,
                        influencer access, PMF access, merchant access,
                        exclusive merchandise, ADGB conferences, discounts, and
                        more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>2. Custom Tokens (8 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 50% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Similar to Owners PMF
                        tokens with some exclusions.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>3. Partners PMF Tokens (180 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 50% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Access to DAO
                        utilities, Buddha Boy artwork, influencer access, and
                        more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>4. Founders PMF Tokens (360 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 30% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Access to basic DAO
                        utilities, Buddha Boy artwork, and more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>5. Funder Associate Tokens (101 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 25% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> NBA Fan Boy NFT art,
                        selected PMF access, and more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>6. Funder Community Tokens (520 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 20% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> NBA Fan Boy NFT art,
                        selected PMF access.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>7. Sponsor Tokens (52,000 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 10% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Local workshops,
                        selected PMF access.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>8. Participant Tokens (101,000 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 10% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Special offers for
                        early buyers, selected PMF access.
                      </li>
                    </ul>
                  </ul>
                </li>
              </ul> */}
              
              <ul>
                <li>Section 2.1: Token Types and Benefits</li>

                <li>
                  <ul>
                    <li><strong>1. Deux Crypto Pioneer - No Price (2 tokens)</strong></li>
                    <ol>
                      <li><strong>Control Over the DAO and its investments</strong></li>
                      <li><strong>Decision Power</strong> – Authority to allocate DAO funds to token members based on contributions</li>
                      <li><strong>Fair Business Advocacy</strong> – Committed to ethical transactions, lobbying for MCAA’s mission</li>
                      <li><strong>Strategic Influence</strong> – Shaping the future of MCAA through business and community initiatives</li>
                    </ol>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li><strong>2. Señor Partner - $1,360,000.00 (12 tokens)</strong></li>
                    <ol>
                      <li><strong>Exclusive Assets</strong> – 1,001 Premium Coins (future cryptocurrency)</li>
                      <li><strong>VIP Event Access</strong> – 10 seats at Holiday Party & Summer Bash + VIP room</li>
                      <li><strong>Brand Exposure</strong> – Walk-in banner, stage & outdoor logos, VIP table logo, featured ad on MCAA calendar</li>
                      <li><strong>Exclusive Perks</strong> – 1 MCAA NFT, CEO 1-on-1 call, T-shirts, identity theft protection up to $1 million</li>
                      <li><strong>Mentorship</strong> – Personal mentor + yacht networking events</li>
                    </ol>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li><strong>3. Partner - $100,000.00 (180 tokens)</strong></li>
                    <ol>
                      <li><strong>Event Access</strong> – Invitation for 4 to any MCAA Annual Event + VIP Bracelets</li>
                      <li><strong>Exclusive Assets</strong> – 18,000 Premium Coins, 1 Buddha Boy NFT, 1 Founder Coin, 1 MCAA NFT</li>
                      <li><strong>Brand Exposure</strong> – Business card & top banner feature in MCAA Calendar (includes complimentary copy), logo on T-shirt, Walk-in Banner, MCAA Magnet</li>
                      <li><strong>Media & Recognition</strong> – 5 copies of the MCAA Annual Magazine</li>
                      <li><strong>Mentorship</strong> – Personal mentor + exclusive networking events</li>
                      <li><strong>Community Access</strong> – Exclusive entry to the MCAA Discord channel</li>
                    </ol>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li><strong>4. Founders - $26,000.00 (360 tokens)</strong></li>
                    <ol>
                      <li><strong>Exclusive Assets</strong> – 1 MCAA NFT, 1 future Premium Coin, 1,800 Premium Coins</li>
                      <li><strong>Investment Access</strong> – Entry to MCAA investor networking events</li>
                      <li><strong>Community & Recognition</strong> – Access to MCAA Discord, bi-annual newsletter (featured in publication)</li>
                      <li><strong>Security Perks</strong> – Complimentary identity theft protection (up to $1 million)</li>
                      <li><strong>Special Access</strong> – Admission to MCAA 101, MCAA 10260, and a dedicated bench at the MCAA Farm (1079 W Veterans Hwy)</li>
                    </ol>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li><strong>5. Funder Associate - $30,000.00 (260 tokens)</strong></li>
                    <ol>
                      <li><strong>Event Access</strong> – 1 seat at a Grand Annual Event + admission to 1 MCAA Annual Event</li>
                      <li><strong>Exclusive Assets</strong> – 1,001 Future Premium Coins + 1 MCAA NFT</li>
                      <li><strong>Business & Exposure</strong> – Quarter-page ad in MCAA Annual Magazine + investment opportunities at networking events</li>
                      <li><strong>Community & Influence</strong> – Access to MCAA Discord + private executive lobbying sessions</li>
                      <li><strong>Security Perks</strong> – Identity theft protection (up to $1 million)</li>
                      <li><strong>Governance & Education</strong> – Admission to MCAA 101 & MCAA 10100 (Early Adopter Class on lobbying, DAO spending, and industry insights)</li>
                      <li><strong>Publishing & Recognition</strong> – 3 Early Adopter tokens = newsletter feature + bi-annual newsletter access</li>
                      <li><strong>Merch & Rewards</strong>:</li>
                      <ul>
                        <li>5 tokens = customized business merch</li>
                        <li>Hold 5 tokens = receive PMF custom merch</li>
                        <li>Sell 7 tokens = earn 1 Partner token</li>
                        <li>Sell 3 tokens = earn 1 Founder token</li>
                        <li>Sell 180 tokens = earn 1 Founder token</li>
                      </ul>
                    </ol>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li><strong>6. Funder Community - $3,600.00 (520 tokens)</strong></li>
                    <ol>
                      <li><strong>Event Access</strong> – Admission to one MCAA networking event + 1 raffle ticket for prizes (Gala, Small Business Events, etc.)</li>
                      <li><strong>Exclusive Assets</strong> – 180 Future Premium Coins</li>
                      <li><strong>Business & Exposure</strong> – Listed as a sponsor on T-shirts (Gala & Holiday Party) + business card feature in MCAA Calendar (1 month)</li>
                      <li><strong>Community & Influence</strong> – Access to MCAA Discord + annual MCAA Newsletter</li>
                      <li><strong>Education & Growth</strong> – Seat in MCAA 101 Class (Intro to MCAA & collaboration)</li>
                      <li><strong>Security Perks</strong> – Identity theft protection (up to $1 million)</li>
                      <li><strong>Consultation & Rewards</strong>:</li>
                      <ul>
                        <li>1-on-1 business consultation with an MCAA professional</li>
                        <li>Sell 260 tokens (including at least one of each type) = Earn 1 Funder Associate token</li>
                        <li>Featured on & receive a complimentary MCAA Calendar</li>
                      </ul>
                    </ol>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li><strong>7. Sponsor - $520.00 (52,00 tokens)</strong></li>
                    <ol>
                      <li><strong>Community Access</strong> – Entry to MCAA Discord</li>
                      <li><strong>Exclusive Assets</strong> – 18 Future Premium Coins</li>
                      <li><strong>Business & Exposure</strong> – Business cards (for MCA members) or MCAA Magnet (for merchants)</li>
                      <li><strong>Media & Recognition</strong> – Complimentary MCAA Calendar + Limited Edition Annual MCAA Magazine</li>
                      <li><strong>Event Perks</strong> – 1 raffle ticket for prizes (Gala, Small Business Events, etc.)</li>
                      <li><strong>Sell 360 Sponsor/Member tokens</strong> = Earn 1 Funder Community token</li>
                    </ol>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li><strong>8. Member - $260.00 (101,000 tokens)</strong> </li>
                    <ol>
                      <li><strong>Community Access</strong> – Entry to MCAA Discord</li>
                      <li><strong>Exclusive Assets</strong> – 1 Future Premium Coin</li>
                      <li><strong>Security Perks</strong> – Complimentary identity theft protection (up to $1 million)</li>
                      <li><strong>Bonus Perks</strong> – First 101 buyers get a free T-shirt</li>
                      <li><strong>Sell 10 Membership tokens</strong> = Earn 1 Sponsor token</li>
                    </ol>
                  </ul>
                </li>
              </ul>


              <ul>
                <li>Section 2.2: Artwork Allocation</li>
                <li>
                  <ul>
                    <li>
                      - Tokens are non-refundable and represent a stake in the
                      DAO.
                    </li>
                    <li>
                      - Token holders must comply with DAO governance and
                      operational rules, as well as applicable laws and
                      regulations.
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                <b>Article 3: Terms of Sale, Transfer, and Compliance</b>
              </p>
              <ul>
                <li>
                  - Tokens are non-refundable and represent a stake in the DAO.
                </li>
                <li>
                  - Token holders must comply with DAO governance and
                  operational rules, as well as applicable laws and regulations.
                </li>
              </ul>

              <p>
                <b>Article 4: Legal and Regulatory Compliance</b>
              </p>
              <ul>
                <li>
                  - The DAO and token holders are subject to all relevant legal
                  and regulatory obligations.
                </li>
              </ul>

              <p>
                <b>Appendices</b>
              </p>
              <ul>
                <li>
                  - Detailed Percentage of DAO distribution, utility rights,
                  Free airdrop percentage structures, and specific art
                  allocations.
                </li>
                <li>
                  - DAO governance rules and token holder responsibilities.
                </li>
              </ul>
              <p className="border-b pb-4">
                <a href="https://metamask.io/buy-crypto/" target="_blank">
                  How to buy crypto currency in metamask using cards, banks of
                  digital wallets tutorial
                </a>
              </p>
              <br />
            </div>
          </motion.div>

          {/* ### BOTTOM BACKGROUND ### */}

          <motion.div
            className="BG transition"
            initial={{
              zIndex: 4,
              left: "-40vw",
              opacity: 1,
              transform: "scale(0.15) translate(-50%, -50%) rotate(-40deg)",
            }}
            animate={{
              zIndex: 3,
              left: "180vw",
              opacity: 1,
              transform: "scale(2.65) translate(-50%, -50%) rotate(0deg)",
            }}
            transition={{ delay: 1, type: "tween", duration: 3 }}
          >
            <img
              src="/train-alt.png"
              alt="Train"
              className="Train"
              priority="true"
            />
          </motion.div>

          <Bottom.BottomBackground
            initialMainBottomAtom={initialMainBottom}
            animateMainBottomAtom={animateMainBottom}
            initialMainLeftAtom={initialMainLeft}
            animateMainLeftAtom={animateMainLeft}
            initialMainOpacityAtom={initialMainOpacity}
            animateMainOpacityAtom={animateMainOpacity}
            initialMainScaleAtom={initialMainScale}
            animateMainScaleAtom={animateMainScale}
            initialMintBottomAtom={initialMintBottom}
            animateMintBottomAtom={animateMintBottom}
            initialMintLeftAtom={initialMintLeft}
            animateMintLeftAtom={animateMintLeft}
            initialMintOpacityAtom={initialMintOpacity}
            animateMintOpacityAtom={animateMintOpacity}
            initialMintScaleAtom={initialMintScale}
            animateMintScaleAtom={animateMintScale}
            initialTimelineBottomAtom={initialTimelineBottom}
            animateTimelineBottomAtom={animateTimelineBottom}
            initialTimelineLeftAtom={initialTimelineLeft}
            animateTimelineLeftAtom={animateTimelineLeft}
            initialTimelineOpacityAtom={initialTimelineOpacity}
            animateTimelineOpacityAtom={animateTimelineOpacity}
            initialTimelineScaleAtom={initialTimelineScale}
            animateTimelineScaleAtom={animateTimelineScale}
            initialAboutBottomAtom={initialAboutBottom}
            animateAboutBottomAtom={animateAboutBottom}
            initialAboutLeftAtom={initialAboutLeft}
            animateAboutLeftAtom={animateAboutLeft}
            initialAboutOpacityAtom={initialAboutOpacity}
            animateAboutOpacityAtom={animateAboutOpacity}
            initialAboutScaleAtom={initialAboutScale}
            animateAboutScaleAtom={animateAboutScale}
            initialFoundersBottomAtom={initialFoundersBottom}
            animateFoundersBottomAtom={animateFoundersBottom}
            initialFoundersLeftAtom={initialFoundersLeft}
            animateFoundersLeftAtom={animateFoundersLeft}
            initialFoundersOpacityAtom={initialFoundersOpacity}
            animateFoundersOpacityAtom={animateFoundersOpacity}
            initialFoundersScaleAtom={initialFoundersScale}
            animateFoundersScaleAtom={animateFoundersScale}
            initialVisionBottomAtom={initialVisionBottom}
            animateVisionBottomAtom={animateVisionBottom}
            initialVisionLeftAtom={initialVisionLeft}
            animateVisionLeftAtom={animateVisionLeft}
            initialVisionOpacityAtom={initialVisionOpacity}
            animateVisionOpacityAtom={animateVisionOpacity}
            initialVisionScaleAtom={initialVisionScale}
            animateVisionScaleAtom={animateVisionScale}
            initialTokenomicsBottomAtom={initialTokenomicsBottom}
            animateTokenomicsBottomAtom={animateTokenomicsBottom}
            initialTokenomicsLeftAtom={initialTokenomicsLeft}
            animateTokenomicsLeftAtom={animateTokenomicsLeft}
            initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
            animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
            initialTokenomicsScaleAtom={initialTokenomicsScale}
            animateTokenomicsScaleAtom={animateTokenomicsScale}
            initialTitleTopAtom={initialTitleTop}
            animateTitleTopAtom={animateTitleTop}
            initialTitleLeftAtom={initialTitleLeft}
            animateTitleLeftAtom={animateTitleLeft}
            initialTitleOpacityAtom={initialTitleOpacity}
            animateTitleOpacityAtom={animateTitleOpacity}
            initialTitleScaleAtom={initialTitleScale}
            animateTitleScaleAtom={animateTitleScale}
          />
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
