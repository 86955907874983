import { useEffect } from "react";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import * as Top from "components/backgrounds/TopBackground";
import * as Bottom from "components/backgrounds/BottomBackground";
import "styles/modal.css";

const Home = () => {
  useEffect(() => {
    const wagmiStatus = window.localStorage.getItem("wagmi.connected");
    const wagmiWallet = window.localStorage.getItem("wagmi.wallet");
    const wagmiData = window.localStorage.getItem("wagmi.store");
    console.log("Wagmi connected: " + wagmiStatus);
    console.log("Wagmi wallet: " + wagmiWallet);
    console.log("Wagmi data: " + wagmiData);
    console.log("Wagmi data: " + wagmiData.state);
  }, []);

  const [skyColor, setSkyColor] = useAtom(Top.skyColorAtom);
  const [initialBGOpacity, setInitialBGOpacity] = useAtom(
    Top.initialBGOpacityAtom
  );
  const [animateBGOpacity, setAnimateBGOpacity] = useAtom(
    Top.animateBGOpacityAtom
  );

  const [initialMintBottom, setInitialMintBottom] = useAtom(
    Bottom.initialMintBottomAtom
  );
  const [animateMintBottom, setAnimateMintBottom] = useAtom(
    Bottom.animateMintBottomAtom
  );
  const [initialMintLeft, setInitialMintLeft] = useAtom(
    Bottom.initialMintLeftAtom
  );
  const [animateMintLeft, setAnimateMintLeft] = useAtom(
    Bottom.animateMintLeftAtom
  );
  const [initialMintOpacity, setInitialMintOpacity] = useAtom(
    Bottom.initialMintOpacityAtom
  );
  const [animateMintOpacity, setAnimateMintOpacity] = useAtom(
    Bottom.animateMintOpacityAtom
  );
  const [initialMintScale, setInitialMintScale] = useAtom(
    Bottom.initialMintScaleAtom
  );
  const [animateMintScale, setAnimateMintScale] = useAtom(
    Bottom.animateMintScaleAtom
  );
  const [initialTimelineBottom, setInitialTimelineBottom] = useAtom(
    Bottom.initialTimelineBottomAtom
  );
  const [animateTimelineBottom, setAnimateTimelineBottom] = useAtom(
    Bottom.animateTimelineBottomAtom
  );
  const [initialTimelineLeft, setInitialTimelineLeft] = useAtom(
    Bottom.initialTimelineLeftAtom
  );
  const [animateTimelineLeft, setAnimateTimelineLeft] = useAtom(
    Bottom.animateTimelineLeftAtom
  );
  const [initialTimelineOpacity, setInitialTimelineOpacity] = useAtom(
    Bottom.initialTimelineOpacityAtom
  );
  const [animateTimelineOpacity, setAnimateTimelineOpacity] = useAtom(
    Bottom.animateTimelineOpacityAtom
  );
  const [initialTimelineScale, setInitialTimelineScale] = useAtom(
    Bottom.initialTimelineScaleAtom
  );
  const [animateTimelineScale, setAnimateTimelineScale] = useAtom(
    Bottom.animateTimelineScaleAtom
  );
  const [initialAboutBottom, setInitialAboutBottom] = useAtom(
    Bottom.initialAboutBottomAtom
  );
  const [animateAboutBottom, setAnimateAboutBottom] = useAtom(
    Bottom.animateAboutBottomAtom
  );
  const [initialAboutLeft, setInitialAboutLeft] = useAtom(
    Bottom.initialAboutLeftAtom
  );
  const [animateAboutLeft, setAnimateAboutLeft] = useAtom(
    Bottom.animateAboutLeftAtom
  );
  const [initialAboutOpacity, setInitialAboutOpacity] = useAtom(
    Bottom.initialAboutOpacityAtom
  );
  const [animateAboutOpacity, setAnimateAboutOpacity] = useAtom(
    Bottom.animateAboutOpacityAtom
  );
  const [initialAboutScale, setInitialAboutScale] = useAtom(
    Bottom.initialAboutScaleAtom
  );
  const [animateAboutScale, setAnimateAboutScale] = useAtom(
    Bottom.animateAboutScaleAtom
  );
  const [initialFoundersBottom, setInitialFoundersBottom] = useAtom(
    Bottom.initialFoundersBottomAtom
  );
  const [animateFoundersBottom, setAnimateFoundersBottom] = useAtom(
    Bottom.animateFoundersBottomAtom
  );
  const [initialFoundersLeft, setInitialFoundersLeft] = useAtom(
    Bottom.initialFoundersLeftAtom
  );
  const [animateFoundersLeft, setAnimateFoundersLeft] = useAtom(
    Bottom.animateFoundersLeftAtom
  );
  const [initialFoundersOpacity, setInitialFoundersOpacity] = useAtom(
    Bottom.initialFoundersOpacityAtom
  );
  const [animateFoundersOpacity, setAnimateFoundersOpacity] = useAtom(
    Bottom.animateFoundersOpacityAtom
  );
  const [initialFoundersScale, setInitialFoundersScale] = useAtom(
    Bottom.initialFoundersScaleAtom
  );
  const [animateFoundersScale, setAnimateFoundersScale] = useAtom(
    Bottom.animateFoundersScaleAtom
  );
  const [initialVisionBottom, setInitialVisionBottom] = useAtom(
    Bottom.initialVisionBottomAtom
  );
  const [animateVisionBottom, setAnimateVisionBottom] = useAtom(
    Bottom.animateVisionBottomAtom
  );
  const [initialVisionLeft, setInitialVisionLeft] = useAtom(
    Bottom.initialVisionLeftAtom
  );
  const [animateVisionLeft, setAnimateVisionLeft] = useAtom(
    Bottom.animateVisionLeftAtom
  );
  const [initialVisionOpacity, setInitialVisionOpacity] = useAtom(
    Bottom.initialVisionOpacityAtom
  );
  const [animateVisionOpacity, setAnimateVisionOpacity] = useAtom(
    Bottom.animateVisionOpacityAtom
  );
  const [initialVisionScale, setInitialVisionScale] = useAtom(
    Bottom.initialVisionScaleAtom
  );
  const [animateVisionScale, setAnimateVisionScale] = useAtom(
    Bottom.animateVisionScaleAtom
  );
  const [initialTokenomicsBottom, setInitialTokenomicsBottom] = useAtom(
    Bottom.initialTokenomicsBottomAtom
  );
  const [animateTokenomicsBottom, setAnimateTokenomicsBottom] = useAtom(
    Bottom.animateTokenomicsBottomAtom
  );
  const [initialTokenomicsLeft, setInitialTokenomicsLeft] = useAtom(
    Bottom.initialTokenomicsLeftAtom
  );
  const [animateTokenomicsLeft, setAnimateTokenomicsLeft] = useAtom(
    Bottom.animateTokenomicsLeftAtom
  );
  const [initialTokenomicsOpacity, setInitialTokenomicsOpacity] = useAtom(
    Bottom.initialTokenomicsOpacityAtom
  );
  const [animateTokenomicsOpacity, setAnimateTokenomicsOpacity] = useAtom(
    Bottom.animateTokenomicsOpacityAtom
  );
  const [initialTokenomicsScale, setInitialTokenomicsScale] = useAtom(
    Bottom.initialTokenomicsScaleAtom
  );
  const [animateTokenomicsScale, setAnimateTokenomicsScale] = useAtom(
    Bottom.animateTokenomicsScaleAtom
  );

  const [initialTitleTop, setInitialTitleTop] = useAtom(
    Bottom.initialTitleTopAtom
  );
  const [animateTitleTop, setAnimateTitleTop] = useAtom(
    Bottom.animateTitleTopAtom
  );
  const [initialTitleLeft, setInitialTitleLeft] = useAtom(
    Bottom.initialTitleLeftAtom
  );
  const [animateTitleLeft, setAnimateTitleLeft] = useAtom(
    Bottom.animateTitleLeftAtom
  );
  const [initialTitleOpacity, setInitialTitleOpacity] = useAtom(
    Bottom.initialTitleOpacityAtom
  );
  const [animateTitleOpacity, setAnimateTitleOpacity] = useAtom(
    Bottom.animateTitleOpacityAtom
  );
  const [initialTitleScale, setInitialTitleScale] = useAtom(
    Bottom.initialTitleScaleAtom
  );
  const [animateTitleScale, setAnimateTitleScale] = useAtom(
    Bottom.animateTitleScaleAtom
  );

  useEffect(() => {
    setSkyColor("#288fe3");
    setInitialBGOpacity(animateBGOpacity);
    setAnimateBGOpacity(1);
  });

  useEffect(() => {});

  useEffect(() => {
    setInitialTitleTop(animateTitleTop);
    setInitialTitleLeft(animateTitleLeft);
    setInitialTitleOpacity(animateTitleOpacity);
    setInitialTitleScale(animateTitleScale);

    setInitialMintBottom(animateMintBottom);
    setInitialMintLeft(animateMintLeft);
    setInitialMintOpacity(animateMintOpacity);
    setInitialMintScale(animateMintScale);
    setInitialTimelineBottom(animateTimelineBottom);
    setInitialTimelineLeft(animateTimelineLeft);
    setInitialTimelineOpacity(animateTimelineOpacity);
    setInitialTimelineScale(animateTimelineScale);
    setInitialAboutBottom(animateAboutBottom);
    setInitialAboutLeft(animateAboutLeft);
    setInitialAboutOpacity(animateAboutOpacity);
    setInitialAboutScale(animateAboutScale);
    setInitialFoundersBottom(animateFoundersBottom);
    setInitialFoundersLeft(animateFoundersLeft);
    setInitialFoundersOpacity(animateFoundersOpacity);
    setInitialFoundersScale(animateFoundersScale);
    setInitialVisionBottom(animateVisionBottom);
    setInitialVisionLeft(animateVisionLeft);
    setInitialVisionOpacity(animateVisionOpacity);
    setInitialVisionScale(animateVisionScale);
    setInitialTokenomicsBottom(animateTokenomicsBottom);
    setInitialTokenomicsLeft(animateTokenomicsLeft);
    setInitialTokenomicsOpacity(animateTokenomicsOpacity);
    setInitialTokenomicsScale(animateTokenomicsScale);

    setAnimateTitleTop("0vh");
    setAnimateTitleLeft("unset");
    setAnimateTitleOpacity(1);
    setAnimateTitleScale("scale(1)");

    setAnimateMintBottom("-0vh");
    setAnimateMintLeft("-0vw");
    setAnimateMintOpacity(0);
    setAnimateMintScale("scale(1)");
    setAnimateTimelineBottom("-0vh");
    setAnimateTimelineLeft("-0vw");
    setAnimateTimelineOpacity(0);
    setAnimateTimelineScale("scale(1)");
    setAnimateAboutBottom("-0vh");
    setAnimateAboutLeft("-0vw");
    setAnimateAboutOpacity(0);
    setAnimateAboutScale("scale(1)");
    setAnimateFoundersBottom("-0vh");
    setAnimateFoundersLeft("-0vw");
    setAnimateFoundersOpacity(0);
    setAnimateFoundersScale("scale(1)");
    setAnimateVisionBottom("-0vh");
    setAnimateVisionLeft("-0vw");
    setAnimateVisionOpacity(0);
    setAnimateVisionScale("scale(1)");
    setAnimateTokenomicsBottom("-0vh");
    setAnimateTokenomicsLeft("-0vw");
    setAnimateTokenomicsOpacity(0);
    setAnimateTokenomicsScale("scale(1)");
  });

  return (
    <div className="RouteWrapper HomeWrapper EnterPage">
      <div className="bg" style={{ zIndex: "-1" }}></div>

      <div className="MobileView">
        <div className="in">
          <div className="in-top">
            <div className="col-12">
              {/*<h1 className='mobile-title mx-auto center'>MCAA</h1>*/}
              <div className="title-arc mt10 mb10">
                <span className="s1">M</span>
                <span className="s2">C</span>
                <span className="s3">A</span>
                <span className="s4">A</span>
              </div>

              <div className="relative">
                <div className="">
                  <img
                    src="/mobile-bg.jpg"
                    alt="Home"
                    className="w-full col-12"
                    priority="true"
                  />
                </div>

                <div className="welcome row m-w-full mx-auto">
                  <div className="col-9 pb30 m-pb0">
                    <span
                      className="m-mt20"
                      style={{
                        display: "block",
                        fontSize: "22px",
                        fontWeight: "bold",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      welcome to
                    </span>
                    <h1 className="common-title mt10 mb-3">
                      MCAA's
                      <br /> Buddha Boy NFT <br />
                      Club
                    </h1>
                    <Link className="mb-only" to="/about">
                      <button className="col-12 m-w-full block" type="button">
                        ENTER
                      </button>
                    </Link>
                    <Link className="dt" to="/map">
                      <button className="col-12 m-w-full block" type="button">
                        ENTER
                      </button>
                    </Link>
                  </div>
                  <div className="col-2">
                    <div className="rotate my-auto">← ABOUT MCAA</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ml20 mr20 row">
              <div className="col-lg-8 col-12">
                <p className="common-p">
                  The MCAA community NFT is the first step towards a global
                  small business journey into the world of decentralized
                  connectivity, utilizing Web3, crypto currency bringing the
                  digital transformation of small business into the future.
                </p>
                <div>
                  <a
                    className="button"
                    href="https://discord.gg/sMefg2cDF4"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Join Discord
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright col-12 center">
            <p className="">
              <small>© 2025 MCAA</small>
            </p>
          </div>
        </div>

        <Bottom.BottomBackground
          initialMintBottomAtom={initialMintBottom}
          animateMintBottomAtom={animateMintBottom}
          initialMintLeftAtom={initialMintLeft}
          animateMintLeftAtom={animateMintLeft}
          initialMintOpacityAtom={initialMintOpacity}
          animateMintOpacityAtom={animateMintOpacity}
          initialMintScaleAtom={initialMintScale}
          animateMintScaleAtom={animateMintScale}
          initialTimelineBottomAtom={initialTimelineBottom}
          animateTimelineBottomAtom={animateTimelineBottom}
          initialTimelineLeftAtom={initialTimelineLeft}
          animateTimelineLeftAtom={animateTimelineLeft}
          initialTimelineOpacityAtom={initialTimelineOpacity}
          animateTimelineOpacityAtom={animateTimelineOpacity}
          initialTimelineScaleAtom={initialTimelineScale}
          animateTimelineScaleAtom={animateTimelineScale}
          initialAboutBottomAtom={initialAboutBottom}
          animateAboutBottomAtom={animateAboutBottom}
          initialAboutLeftAtom={initialAboutLeft}
          animateAboutLeftAtom={animateAboutLeft}
          initialAboutOpacityAtom={initialAboutOpacity}
          animateAboutOpacityAtom={animateAboutOpacity}
          initialAboutScaleAtom={initialAboutScale}
          animateAboutScaleAtom={animateAboutScale}
          initialFoundersBottomAtom={initialFoundersBottom}
          animateFoundersBottomAtom={animateFoundersBottom}
          initialFoundersLeftAtom={initialFoundersLeft}
          animateFoundersLeftAtom={animateFoundersLeft}
          initialFoundersOpacityAtom={initialFoundersOpacity}
          animateFoundersOpacityAtom={animateFoundersOpacity}
          initialFoundersScaleAtom={initialFoundersScale}
          animateFoundersScaleAtom={animateFoundersScale}
          initialVisionBottomAtom={initialVisionBottom}
          animateVisionBottomAtom={animateVisionBottom}
          initialVisionLeftAtom={initialVisionLeft}
          animateVisionLeftAtom={animateVisionLeft}
          initialVisionOpacityAtom={initialVisionOpacity}
          animateVisionOpacityAtom={animateVisionOpacity}
          initialVisionScaleAtom={initialVisionScale}
          animateVisionScaleAtom={animateVisionScale}
          initialTokenomicsBottomAtom={initialTokenomicsBottom}
          animateTokenomicsBottomAtom={animateTokenomicsBottom}
          initialTokenomicsLeftAtom={initialTokenomicsLeft}
          animateTokenomicsLeftAtom={animateTokenomicsLeft}
          initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
          animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
          initialTokenomicsScaleAtom={initialTokenomicsScale}
          animateTokenomicsScaleAtom={animateTokenomicsScale}
          initialTitleTopAtom={initialTitleTop}
          animateTitleTopAtom={animateTitleTop}
          initialTitleLeftAtom={initialTitleLeft}
          animateTitleLeftAtom={animateTitleLeft}
          initialTitleOpacityAtom={initialTitleOpacity}
          animateTitleOpacityAtom={animateTitleOpacity}
          initialTitleScaleAtom={initialTitleScale}
          animateTitleScaleAtom={animateTitleScale}
        />
      </div>
    </div>
  );
};

export default Home;
